/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable no-magic-numbers */
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Backdrop, CircularProgress, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AppContext from '../context/AppContext';
import logoMercado from '../images/logo-mercado.png';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '50%',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: '1em',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('lg')]: {
      width: '35ch',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

export default function Header() {
  const { setMapItens, loading, setLoading, setCarrinho, qtdItens, setQtdItens } = useContext(AppContext);
  const [busca, setBusca] = useState('');
  const history = useHistory();

  useEffect(() => {
    const handleAtualizarCarrinho = () => {
      const carrinho = JSON.parse(localStorage.getItem('carrinhomm')) || [];
      const totalItens = carrinho.reduce((acc, item) => acc + item.qtd, 0);
      setQtdItens(totalItens);
      setCarrinho(carrinho);
    };
    handleAtualizarCarrinho();
    window.addEventListener('atualizarCarrinho', handleAtualizarCarrinho);
    handleAtualizarCarrinho();
    return () => {
      window.removeEventListener('atualizarCarrinho', handleAtualizarCarrinho);
    };
  }, [setCarrinho, setQtdItens]);

  const testeFunc = async (string) => {
    setLoading(true);
    const response = await fetch(`https://api.mercadolibre.com/sites/MLB/search?category=MLB5672&q=${string}`);
    const data = await response.json();
    setMapItens(data.results);
    setLoading(false);
  };

  return (
    <Box sx={ { flexGrow: 1, display: 'flex' } }>
      <Backdrop sx={ { color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 } } open={ loading }>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar position="static" style={ { backgroundColor: '#ffe600', color: 'black', display: 'flex', justifyContent: 'center', justifyItems: 'center' } }>
        <Toolbar className="flex justify-between">
          <button
            onClick={ async () => {
              history.push('/');
              await testeFunc('');
              setBusca('');
            } }
          >
            <img
              className="p-3 lg:w-52 w-40"
              src={ logoMercado }
              alt="logoMercado"
            />
          </button>
          <Search className="flex">
            <StyledInputBase
              placeholder="Buscar produtos, marcas e muito mais..."
              inputProps={ { 'aria-label': 'search' } }
              value={ busca }
              onChange={ (e) => setBusca(e.target.value) }
            />
            <IconButton
              onClick={ async () => {
                await testeFunc(busca);
              } }
              size="large"
              aria-label="search"
              color="inherit"
            >
              <SearchIcon />
            </IconButton>
          </Search>
          <button onClick={ () => history.push('/carrinho') } className="relative">
            <div className="pl-2 relative">
              <ShoppingCartOutlinedIcon />
              {qtdItens > 0 && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                  {qtdItens}
                </span>
              )}
            </div>
          </button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
