/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
import * as React from 'react';
import PIX from 'react-qrcode-pix';
import { useContext, useEffect, useRef, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, DialogContentText, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AppContext from '../context/AppContext';

const now = new Date().getTime().toString();

export default function PixT() {
  const { carrinho, dadosCliente } = useContext(AppContext);
  const [fullPIX, setFullPIX] = useState('');
  const [open, setOpen] = React.useState(false);
  const valorTotal = carrinho
    .reduce((acc, curr) => acc + curr.qtd * curr.price, 0) * 0.60;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.value = fullPIX;
    }
  }, [fullPIX]);

  return (
    <div className="flex flex-col items-center">
      <PIX
        pixkey="financeiro@autopecastoncar.com"
        merchant="Mercado Auto Peca"
        city="São Paulo"
        cep="01.310-930"
        code={ `RQP${now}` }
        amount={ valorTotal }
        onLoad={ setFullPIX }
        resize={ 200 }
        variant="fluid"
        padding={ 10 }
        color="#357"
        bgColor="white"
        bgRounded
      />
      <div
        className="mt-3"
      >
        <div className="max-w-96" />
        <CopyToClipboard
          id="whats"
          text={ fullPIX }
        >
          <TextField
            id="whats"
            className="w-full"
            disabled
            inputRef={ textRef }
            label="Pix Copia e Cola"
            fullWidth
            onClick={ handleClick }
            InputProps={ {
              endAdornment: (
                <InputAdornment id="whats" position="end">
                  <IconButton
                    id="whats"
                    aria-label="copiar texto"
                    edge="end"
                  >
                    <ContentCopyIcon id="whats" />
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        </CopyToClipboard>
        <div className={ `mt-3 ${open ? '' : 'hidden'}` }>
          <Alert
            severity="success"
          >
            Pix Copia e Cola copiado com sucesso!
          </Alert>
        </div>
      </div>
      <DialogContentText className="w-full pt-3">
        <div className="w-full">
          <p>Após o pagamento é gerado automaticamente a NF-e, Certificado de Garantia e Código de Rastreio.</p>
          { dadosCliente.email.length ? (
            <p>{`Será enviado após o pagamento para o email ${dadosCliente.email} toda esse documentação.`}</p>
          ) : ''}
          <p>Parabéns por comprar conosco!</p>
        </div>
      </DialogContentText>
    </div>
  );
}
