import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Item from './Item';
import Carrinho from './Carrinho';

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={ Home } />
      <Route exact path="/item/:id" component={ Item } />
      <Route exact path="/carrinho" component={ Carrinho } />
    </Switch>
  );
}

export default Routes;
