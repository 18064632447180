/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Typography } from '@mui/material';
import AppContext from '../context/AppContext';

export default function Itens() {
  const { mapItens, setMapItens, setLoading } = useContext(AppContext);
  const [mapLocal, setMapLocal] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const fetchImages = async () => {
      const newMap = await Promise.all(mapItens.map(async (e) => {
        const response = await fetch(`https://api.mercadolibre.com/items/${e.id}`);
        const data = await response.json();
        return {
          ...e,
          imgOne: data.pictures,
        };
      }));
      setMapLocal(newMap);
      setLoading(false);
    };

    fetchImages();
  }, [mapItens, setLoading]);

  return (
    <div className="bg-gray-100 w-full flex justify-center">
      <div
        className="pt-2 pr-2 flex flex-col sm:flex-row
       flex-wrap justify-center sm:w-[960px] w-full"
      >
        { mapLocal?.map((item, i) => (
          <button
            key={ i }
            className="border-[1px] bg-white sm:w-[300px] w-full
             h-96 flex justify-center m-1 pt-2 rounded-xl shadow-md"
            onClick={ () => history.push(`/item/${item.id}`) }
          >
            <div className="flex flex-col w-full">
              <div className="w-full h-52 flex justify-center border-b-[1px] mb-2">
                <img
                  className="rounded-xl h-full"
                  src={ item.imgOne ? item.imgOne[0].url : item.thumbnail }
                  alt={ item.title }
                />
              </div>
              <div>
                <p
                  className="text-left text-[14px] text-gray-700 px-3
                  h-[55px] overflow-hidden"
                  style={ {
                    lineHeight: 1.3,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'break-spaces' } }
                >
                  {item.title}
                </p>
                <p
                  className="text-left px-3 text-[15px] text-gray-400 mt-3"
                  style={ { textDecoration: 'line-through' } }
                >
                  {`R$ ${Number(item.price)
                    .toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}
                </p>
                <p className="text-left px-3 text-[25px] font-light">
                  {`R$ ${(Number(item.price) * 0.60).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
