import React from 'react';
import Header from '../components/Header';
import Itens from '../components/Itens';

export default function Home() {
  return (
    <div>
      <Header />
      <Itens />
    </div>
  );
}
