/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-depth */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../components/Header';
import Caracteristicas from '../components/Caracteristicas';

export default function Item() {
  const history = useHistory();
  const [item, setItem] = useState({});
  const [imageSelect, setImageSelect] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await fetch(`https://api.mercadolibre.com/items/${id}`);
        const data = await response.json();
        setItem(data);
        setImageSelect(data.pictures?.[0]?.url || '');
      } catch (error) {
        console.error('Erro ao buscar item:', error);
      }
    };
    fetchItem();
  }, [id]);

  const adicionarCarrinho = (itemObj) => {
    const itensStorage = localStorage.getItem('carrinhomm');
    if (!itensStorage) {
      localStorage.setItem('carrinhomm', JSON.stringify([{ ...itemObj, qtd: 1 }]));
    } else {
      const arrStorage = JSON.parse(itensStorage);
      const itemIndex = arrStorage.findIndex((it) => it.id === itemObj.id);
      if (itemIndex !== -1) {
        arrStorage[itemIndex].qtd += 1;
      } else {
        arrStorage.push({ ...itemObj, qtd: 1 });
      }
      localStorage.setItem('carrinhomm', JSON.stringify(arrStorage));
    }
    const evento = new Event('atualizarCarrinho');
    window.dispatchEvent(evento);
  };

  return (
    <div className="w-full flex flex-col items-center bg-gray-100">
      <div className="w-full">
        <Header />
      </div>
      <div className="flex lg:flex-row flex-col justify-center mt-5 lg:w-[80%] w-[95%]">
        {/* // imagens */}
        <div className="flex flex-col lg:flex-row justify-center bg-white rounded-xl">
          <div>
            <div className="lg:hidden w-full h-96">
              <img className="w-full h-full object-contain" src={ imageSelect } alt="imagem" />
            </div>
            <div className="flex lg:flex-col overflow-auto lg:overflow-x-hidden lg:h-[450px]">
              {item.pictures?.map((foto, i) => (
                <div
                  key={ `${foto.url}-${i}` }
                  className={ `h-22 w-22 flex justify-center rounded-lg ${imageSelect === foto.url ? 'border-blue-600 border-2' : 'border-[1px]'}` }
                >
                  <img
                    onMouseEnter={ () => setImageSelect(foto.url) }
                    className="m-1 h-20"
                    src={ foto.url }
                    alt={ foto.url }
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-[500px] h-[500px] lg:flex justify-center hidden rounded-xl">
            <img className="h-full rounded-lg" src={ imageSelect } alt="imagem" />
          </div>
        </div>

        <div className="flex flex-col border-[1px] rounded-xl lg:ml-1 mt-1 lg:mt-0 lg:w-[500px] p-2 lg:h-[500px] bg-white">
          <p className="font-medium lg:text-[20px] sm:text-[17px] text-[15px]">{item.title}</p>
          <p
            className="text-left text-[15px] text-gray-400 mt-3"
            style={ { textDecoration: 'line-through' } }
          >
            {`R$ ${Number(item.price)
              .toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}
          </p>
          <p className="text-left text-[25px] font-light">
            {`R$ ${(Number(item.price) * 0.60).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          </p>
          <p className="lg:text-[17px] text-[14px]">
            <span className="text-[#25b369]">Chegará grátis</span>
            {' '}
            em 1 dia útil
          </p>
          <p className="text-[#25b369] mt-2 lg:text-[17px] text-[14px]">Devolução grátis</p>
          <p className="text-gray-600 lg:text-[17px] text-[14px]">Você tem 30 dias a partir da data de recebimento.</p>
          <Button
            onClick={ () => {
              adicionarCarrinho(item);
              history.push('/carrinho');
            } }
            variant="contained"
            sx={ { marginTop: 4 } }
          >
            Comprar
          </Button>
          <Button
            onClick={ () => adicionarCarrinho(item) }
            variant="contained"
            sx={ {
              marginTop: 2,
              backgroundColor: '#e3edfb',
              color: '#3685fa',
              '&:hover': {
                backgroundColor: '#d9e7fa',
              },
            } }
          >
            Adicionar ao Carrinho
          </Button>
        </div>
      </div>
      <Divider className="lg:w-full" variant="inset" sx={ { margin: 'auto', marginTop: 2, marginBottom: 2 } } />
      <div className="lg:w-[75%] w-[95%]">
        <Caracteristicas arrCarac={ item.attributes } />
      </div>
    </div>
  );
}
