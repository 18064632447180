import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import AppContext from './AppContext';

function AppProvider({ children }) {
  const [mapItens, setMapItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qtdItens, setQtdItens] = useState([]);
  const [carrinho, setCarrinho] = useState([]);
  const [compra, setCompra] = useState(false);
  const [dadosCliente, setDadosCliente] = useState({
    nome: '',
    cpf: '',
    email: '',
    telefone: '',
    endereco: { },
    checked: false,
  });

  useEffect(() => {
    const fetchItens = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://api.mercadolibre.com/sites/MLB/search?category=MLB5672');
        const data = await response.json();
        setMapItens(data.results);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar itens:', error);
        setLoading(false);
      }
    };
    fetchItens();
  }, []);

  const values = useMemo(() => ({
    mapItens,
    setMapItens,
    loading,
    setLoading,
    qtdItens,
    setQtdItens,
    carrinho,
    setCarrinho,
    compra,
    setCompra,
    dadosCliente,
    setDadosCliente,
  }), [mapItens, loading, qtdItens, carrinho, compra, dadosCliente]);

  return (
    <AppContext.Provider value={ values }>
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
