/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/prop-types */
import { Table, TableContainer, TableRow, styled } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import React from 'react';

export default function Caracteristicas({ arrCarac }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <div className="flex flex-col w-full items-center">
      <p
        className="font-medium text-[20px] w-full"
      >
        Características do produto
      </p>
      <div className="flex justify-center">
        <div className="m-2">
          <p className="font-bold text-[14px]">Caracteristicas pricipais</p>
          <TableContainer component={ Paper }>
            <Table
              sx={ { width: 350 } }
              aria-label="customized table"
            >
              <TableBody>
                {arrCarac?.map((row, i) => (
                  i > 10 ? '' : (
                    <StyledTableRow key={ `${row.value_name}-${i}` }>
                      <StyledTableCell component="th" scope="row">
                        <p className="font-bold text-[12px]">{row.name}</p>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <p className="text-[12px]">
                          {row.value_name}
                        </p>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="m-2 lg:flex lg:flex-col hidden">
          <p className="font-bold text-[14px]">Outras</p>
          <TableContainer component={ Paper }>
            <Table
              sx={ { width: 350 } }
              aria-label="customized table"
            >
              <TableBody>
                {arrCarac?.map((row, i) => (
                  (i <= 10) || (i > 20) ? '' : (
                    <StyledTableRow key={ `${row.name}-${i}` }>
                      <StyledTableCell component="th" scope="row">
                        <p className="font-bold text-[12px]">{row.name}</p>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <p className="text-[12px]">
                          {row.value_name}
                        </p>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
