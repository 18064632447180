import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import Routes from './pages/Routes';
import AppProvider from './context/AppProvider';

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Routes />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
