/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Box, CircularProgress, Divider } from '@mui/material';
import AppContext from '../context/AppContext';
import PixT from '../Pix/Pix';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [errors, setErrors] = React.useState({});
  const [temporario, setTemporario] = React.useState(false);
  const { dadosCliente, setDadosCliente, carrinho, qtdItens } = React.useContext(AppContext);
  const valorTotal = carrinho
    .reduce((acc, curr) => acc + curr.qtd * curr.price, 0) * 0.60;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStep(1);
    setErrors({});
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateStep = () => {
    const tempErrors = {};
    if (step === 1) {
      if (!dadosCliente.nome) tempErrors.nome = 'Nome é obrigatório';
      if (!dadosCliente.cpf) tempErrors.cpf = 'CPF é obrigatório';
      if (!dadosCliente.telefone) tempErrors.telefone = 'Telefone é obrigatório';
      if (dadosCliente.email && !validateEmail(dadosCliente.email)) tempErrors.email = 'Email inválido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const changeStateTemporarily = () => {
    setTemporario(true);
    setTimeout(() => {
      setTemporario(false);
    }, 2000);
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep((prevStep) => prevStep + 1);
    }
    if (step === 2) {
      changeStateTemporarily();
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateStep()) {
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      console.log(formJson);
      handleClose();
    }
  };

  return (
    <div className="w-[95%] flex justify-center mb-10">
      <div className="w-full flex flex-col items-center">
        <Button
          variant="contained"
          className="lg:w-full sm:w-[300px] w-full"
          onClick={ handleClickOpen }
          disabled={ !(dadosCliente.endereco && dadosCliente.endereco.uf) }
        >
          Continuar a compra
        </Button>
        <Alert className="mt-3" severity="info">Complete as informações acima</Alert>
      </div>
      <Dialog
        className="w-full"
        open={ open }
        onClose={ handleClose }
        PaperProps={ {
          component: 'form',
          onSubmit: handleSubmit,
        } }
      >
        { step === 1 ? (
          <DialogTitle>Dados para NF-e</DialogTitle>
        ) : ''}
        { step === 2 ? (
          <DialogTitle>Resumo da Compra</DialogTitle>
        ) : ''}
        { step === 3 ? (
          <DialogTitle>Dados para Pagamento</DialogTitle>
        ) : ''}
        <DialogContent className="w-full">
          <DialogContentText>
            {step === 1
              ? 'Complete os dados abaixo para gerarmos a NF-e, Certificado de Garantia e Código de rastreio após o pagamento'
              : ''}
            {step === 2
              ? 'Verifique todas informações'
              : ''}
            {step === 3
              ? (
                <div>
                  { temporario ? '' : (
                    <div>
                      <p>Proprietário da Loja: Nicolas Brener Goncalves e Silva</p>
                      <p>
                        {`Valor Total: R$ ${Number(valorTotal)
                          .toLocaleString('pt-BR', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                      </p>
                    </div>
                  )}
                </div>
              )
              : ''}
          </DialogContentText>
          {step === 1 && (
            <>
              <TextField
                onChange={ (e) => setDadosCliente((prev) => ({
                  ...prev,
                  nome: e.target.value,
                })) }
                value={ dadosCliente.nome }
                autoFocus
                required
                margin="dense"
                id="name"
                name="nome"
                label="Nome Completo"
                type="text"
                fullWidth
                variant="standard"
                error={ !!errors.nome }
                helperText={ errors.nome }
              />
              <TextField
                onChange={ (e) => setDadosCliente((prev) => ({
                  ...prev,
                  cpf: e.target.value,
                })) }
                value={ dadosCliente.cpf }
                required
                margin="dense"
                id="cpf"
                name="cpf"
                label="CPF"
                type="number"
                fullWidth
                variant="standard"
                error={ !!errors.cpf }
                helperText={ errors.cpf }
              />
              <TextField
                onChange={ (e) => setDadosCliente((prev) => ({
                  ...prev,
                  email: e.target.value,
                })) }
                value={ dadosCliente.email }
                autoFocus
                margin="dense"
                id="email"
                name="email"
                label="E-mail (Opcional)"
                type="email"
                fullWidth
                variant="standard"
                error={ !!errors.email }
                helperText={ errors.email }
              />
              <TextField
                onChange={ (e) => setDadosCliente((prev) => ({
                  ...prev,
                  telefone: e.target.value,
                })) }
                value={ dadosCliente.telefone }
                required
                margin="dense"
                id="telefone"
                name="telefone"
                label="Telefone com DDD"
                type="number"
                fullWidth
                variant="standard"
                error={ !!errors.telefone }
                helperText={ errors.telefone }
              />
            </>
          )}
          {step === 2 && (
            <div>
              { carrinho.map((e, i) => (
                <div
                  key={ i }
                  className={ `flex ${i !== 0 ? 'border-t-[1px]' : ''} 
                 sm:flex-row flex-col` }
                >
                  <div className="flex sm:w-[80%] w-full pr-1 py-2">
                    <img
                      className="p-1 w-20 lg:w-28 h-20 lg:h-28"
                      src={ e.thumbnail }
                      alt={ e.title }
                    />
                    <p>{e.title}</p>
                  </div>
                  <div
                    className="flex flex-col sm:items-center sm:w-[20%] mb-5 ml"
                  >
                    <p>Quantidade:</p>
                    <div
                      className="flex border-[1px] justify-between w-24 rounded-md"
                    >
                      <p className="">
                        {e.qtd}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <Divider />
              <div>
                <p className="mb-3 mt-3">Dados Pessoais</p>
                <p>
                  Nome:
                  <span className="font-light">{` ${dadosCliente.nome}`}</span>
                </p>
                <p>
                  CPF:
                  <span className="font-light">{` ${dadosCliente.cpf}`}</span>
                </p>
                { dadosCliente.email.length ? (
                  <p>
                    E-mail:
                    <span className="font-light">{` ${dadosCliente.email}`}</span>
                  </p>
                ) : ''}
                <p className="mb-3">
                  Telefone:
                  <span className="font-light">{` ${dadosCliente.telefone}`}</span>
                </p>
                <Divider />
                <p className="mt-3 mb-3">Endereço de entrega</p>
                <p className="font-light">
                  {`${dadosCliente.endereco.logradouro}, ${dadosCliente.numero} ${dadosCliente.checked ? dadosCliente.complemento : ''}`}
                </p>
                <p className="font-light">
                  {`${dadosCliente.endereco.localidade}, ${dadosCliente.endereco.bairro} - ${dadosCliente.endereco.uf}`}
                </p>
              </div>
              <div className="flex items-center mt-4 justify-between">
                <p>Prazo</p>
                <p className="lg:ml-5 mr-3 text-[#25b369]">
                  1 dia útil para entrega
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="">{`Produtos(${qtdItens})`}</p>
                <p className="lg:ml-5 mr-3">
                  {`R$ ${Number(valorTotal)
                    .toLocaleString('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </p>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="w-full">
              { temporario ? (
                <Box sx={ { display: 'flex', width: '100%', height: '200px', justifyContent: 'center' } }>
                  <CircularProgress />
                </Box>
              ) : (
                <PixT />
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleClose }>Cancelar</Button>
          {step > 1 && <Button onClick={ handleBack }>Voltar</Button>}
          {step < 2 && <Button onClick={ handleNext }>Próximo</Button>}
          {step === 2 && <Button onClick={ handleNext }>Ir para pagamento</Button>}
          {step === 3 && <Button type="submit">Concluir</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
