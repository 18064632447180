/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material';
import Header from '../components/Header';
import AppContext from '../context/AppContext';
import FormDialog from '../components/FormDados';
import logoPix from '../images/logo-pix.png';

export default function Carrinho() {
  const { dadosCliente,
    carrinho, setCarrinho, setQtdItens, qtdItens, setLoading, setDadosCliente,
  } = useContext(AppContext);
  const [cep, setCep] = useState('');
  const [endereço, setEndereço] = useState(null);

  const valorTotal = carrinho
    .reduce((acc, curr) => acc + curr.qtd * curr.price, 0) * 0.60;

  const removeItem = (item) => {
    const { id, qtd } = item;
    let updatedCarrinho;
    if (qtd === 1) {
      updatedCarrinho = carrinho.filter((e) => e.id !== id);
    } else {
      updatedCarrinho = carrinho.map((e) => (e.id === id ? { ...e, qtd: e.qtd - 1 } : e));
    }
    localStorage.setItem('carrinhomm', JSON.stringify(updatedCarrinho));
    setCarrinho(updatedCarrinho);

    const totalItens = updatedCarrinho.reduce((acc, it) => acc + it.qtd, 0);
    setQtdItens(totalItens);
  };

  const addItem = (i) => {
    const newCarrinho = [...carrinho];
    newCarrinho[i].qtd += 1;
    localStorage.setItem('carrinhomm', JSON.stringify(newCarrinho));
    setQtdItens(newCarrinho.reduce((acc, item) => acc + item.qtd, 0));
    setCarrinho(newCarrinho);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCepChange = async (event) => {
    if (event.target.value.length <= 8) {
      setCep(event.target.value.replace(/\D/g, ''));
    }
    if (event.target.value.replace(/\D/g, '').length === 8) {
      setLoading(true);
      const cep2 = event.target.value;
      const url = `https://viacep.com.br/ws/${cep2}/json/`;

      try {
        const response = await fetch(url);
        if (response.ok) {
          const enderecoResponse = await response.json();
          setEndereço(enderecoResponse);
          setDadosCliente((prev) => {
            return {
              ...prev,
              endereco: enderecoResponse,
            };
          });
          setLoading(false);
        } else {
          console.error('Erro ao buscar o endereço');
          setLoading(false);
        }
      } catch (error) {
        console.error(`Erro na solicitação: ${error}`);
      }
    } else {
      setDadosCliente((prev) => {
        return {
          ...prev,
          endereço: null,
        };
      });
    }
  };

  return (
    <div className="w-full flex flex-col items-center bg-gray-100 h-screen">
      <div className="w-full">
        <Header />
      </div>
      <div className="mt-5 flex lg:flex-row flex-col w-[95%]">
        <div
          className="lg:w-[70%] w-[100%] border-2 rounded-lg
         bg-white"
        >
          <p className="m-3 font-bold">Carrinho de compras</p>
          <Divider
            className="lg:w-full"
            variant="inset"
            sx={ { margin: 'auto', marginTop: 2, marginBottom: 2 } }
          />
          { carrinho.length === 0 ? (
            <p className="h-20 flex ml-3">Nenhum produto no carrinho!</p>
          ) : ''}
          { carrinho.map((e, i) => (
            <div
              key={ i }
              className={ `flex ${i !== 0 ? 'border-t-[1px]' : ''} 
              m-1 sm:flex-row flex-col` }
            >
              <div className="flex sm:w-[80%] w-full mr-2 pr-1 py-2">
                <img
                  className="p-1 w-20 lg:w-28 h-20 lg:h-28"
                  src={ e.thumbnail }
                  alt={ e.title }
                />
                <p>{e.title}</p>
              </div>
              <div
                className="flex flex-col sm:items-center sm:w-[20%] mb-5 ml-5"
              >
                <p>Quantidade:</p>
                <div
                  className="flex border-[1px] justify-between w-24 rounded-md"
                >
                  <button
                    onClick={ () => removeItem(e) }
                    className={ `px-3 ${e.qtd === 0 ? 'text-gray-300' : 'text-black'} 
                    font-bold` }
                    disabled={ e.qtd === 0 }
                  >
                    −
                  </button>
                  <p className="">
                    {e.qtd}
                  </p>
                  <button
                    onClick={ () => addItem(i) }
                    className={ `px-3 ${e.qtd > 10 ? 'text-gray-300' : 'text-black'} 
                    font-bold` }
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="lg:w-[30%] w-[100%] border-2 min-h-[700px]
        rounded-lg lg:ml-2 mt-4 lg:mt-0 bg-white"
        >
          <p className="m-3 font-bold">Resumo da compra</p>
          <Divider
            className="lg:w-full"
            variant="inset"
            sx={ { margin: 'auto', marginTop: 2, marginBottom: 2 } }
          />
          <div className="flex flex-col h-72 justify-between">

            {/* Produto e frete */}
            <div className="">
              <div className="flex items-center justify-between">
                <p className="lg:ml-5 ml-3">{`Produtos(${qtdItens})`}</p>
                <p className="lg:ml-5 mr-3">
                  {`R$ ${Number(valorTotal)
                    .toLocaleString('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="lg:ml-5 ml-3">Frete</p>
                <p className="lg:ml-5 mr-3 text-[#25b369]">
                  Grátis para 1ª compra
                </p>
              </div>
              <div
                className="flex sm:flex-row flex-col
               justify-between sm:items-center mt-3"
              >
                <p className="lg:ml-5 ml-3 mb-4 font-light">Calcular prazo de entrega:</p>
                <TextField
                  size="small"
                  error={ endereço && endereço.erro }
                  helperText={ endereço && endereço.erro ? 'CEP Inválido' : '' }
                  style={ { marginLeft: '10px', marginRight: '10px' } }
                  className="my-2"
                  id="outlined-basic"
                  label="Insira o CEP"
                  variant="outlined"
                  value={ cep }
                  onChange={ (e) => {
                    if (cep.replace(/\D/g, '').length <= 8) {
                      handleCepChange(e);
                    }
                  } }
                />
              </div>
              <div className="ml-3 lg:ml-0">
                <p
                  className="lg:ml-3 mt-4 font-light"
                >
                  {endereço && endereço.logradouro ? endereço.logradouro : ''}
                </p>
                { endereço && endereço.logradouro ? (
                  <div className="lg:ml-3 flex flex-col">
                    <TextField
                      size="small"
                      style={ {
                        marginRight: '10px',
                        width: '120px',
                        marginTop: '5px' } }
                      type="number"
                      className="my-2"
                      id="outlined-basic"
                      label="Número"
                      variant="outlined"
                      value={ dadosCliente.numero }
                      onChange={ (e) => {
                        setDadosCliente((prev) => {
                          return {
                            ...prev,
                            numero: e.target.value,
                          };
                        });
                      } }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ dadosCliente.checked } // Passo 3: Passe o estado para a prop checked
                          onChange={ (e) => setDadosCliente((prev) => {
                            return {
                              ...prev,
                              checked: e.target.checked,
                            };
                          }) } // Passo 4: Adicione a função de mudança de estado
                        />
                      }
                      label="Complemento"
                    />
                    { dadosCliente.checked ? (
                      <TextField
                        size="small"
                        style={ {
                          marginRight: '10px',
                          width: '150px',
                          marginTop: '5px' } }
                        className="my-2"
                        id="outlined-basic"
                        label="Complemento"
                        variant="outlined"
                        value={ dadosCliente.complemento }
                        onChange={ (e) => {
                          setDadosCliente((prev) => {
                            return {
                              ...prev,
                              complemento: e.target.value,
                            };
                          });
                        } }
                      />
                    ) : ''}
                  </div>
                ) : ''}
                <p
                  className="lg:ml-3 font-light"
                >
                  {endereço && endereço.bairro ? endereço.bairro : ''}
                </p>
                <p
                  className="lg:ml-3 font-light"
                >
                  {endereço && endereço.localidade ? endereço.localidade : ''}
                </p>
                <p
                  className="lg:ml-3 font-light"
                >
                  {endereço && endereço.uf ? endereço.uf : ''}
                </p>
              </div>
              <div className="flex items-center mt-4 justify-between">
                <p className="lg:ml-5 ml-3">Prazo</p>
                <p className="lg:ml-5 mr-3 text-[#25b369]">
                  1 dia útil para entrega
                </p>
              </div>
            </div>

            {/* total e btn */}
            <div className="flex flex-col justify-center">
              <div className="flex justify-between items-center my-3">
                <p className="lg:ml-5 ml-3">Forma de Pagamento</p>
                <img src={ logoPix } alt="logo-pix" className="w-28 pr-3" />
              </div>
              <div className="flex justify-between">
                <p className="lg:ml-3 text-[23px] pl-3">Total</p>
                <p className="lg:ml-3 text-[23px] mr-3">
                  {`R$ ${Number(valorTotal)
                    .toLocaleString('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </p>
              </div>
              <div className="w-full justify-center items-center flex flex-col my-4">
                <FormDialog />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
